import React, { useEffect, useRef, useState } from "react";
import LOGO from "./../assets/images/vyapar_logo.svg";
import { Link, useParams } from "react-router-dom";
import { PiArrowLeftBold } from "react-icons/pi";
import { ApisService } from "../app/core/apis.service";
import { dataDecrypt, dataEncrypt } from "../app/utils/encryption-helper";
import { Card } from "react-bootstrap";
import { AppConfig } from "../app/config";
import { useSelector } from "react-redux";
import LoginOtp from "./LoginOtp";
import { GET_PRESIGNED_URL } from "../app/core/common.srvice";
import { S3Helper } from "../app/utils/s3-helper";

export const PurchaseOrder = () => {
  const routerParams = useParams();
  const userData = useSelector((state: any) => state.UserDataReducer);
  const [otpReference, setOtpReference] = useState("");
  const [transactionData, setTransactionData] = useState<any[]>([]);
  const fileInputRef = useRef<any>(null);
  const [presignedUrl,setPresignedUrl]=useState<any>();

  useEffect(() => {
    loadTransaction();
  }, []);

  async function loadTransaction(): Promise<void> {
    try {
      const res = await ApisService.getTransactionData(
        "getById_v2",
        routerParams.id || ""
      );
      console.log("resssssss ", res);

      const data: any = res.data.data;
      const transactionData = JSON.parse(dataDecrypt(data));
      setTransactionData(transactionData);
      const preparedReference = dataEncrypt(
        JSON.stringify({
          id: transactionData[0].transactionId,
          type: "purchase-order",
        })
      );
      setOtpReference(preparedReference);
    } catch (error) {
      console.error("Error in transaction:", error);
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files;
    if (file) {
      try {
        const payload = { filename: file[0].name, location: "terms" };
        const res = await GET_PRESIGNED_URL(payload);
        if (res?.data?.url) {
          const presignedUrl = res.data.url;
          if (presignedUrl) {
            await S3Helper.uploadFilesToS3BySigned(
              presignedUrl,
              file[0],
              file[0].type
            );
            const uploadedFileUrl = presignedUrl.split("?")[0];
            setPresignedUrl(uploadedFileUrl);
          } else {
            console.error(`No presigned URL found for file: ${file[0].name}`);
          }
        } else {
          console.error("Presigned URLs not found in the response.");
        }
      } catch (error) {
        console.log("Error during file upload:", error);
      }
      finally {
        event.target.value = null;
      }
    }
  };
  const handleOpenFile = (uploadedFileUrl:any) => {
    if (uploadedFileUrl) {
      window.open(uploadedFileUrl, "_blank");
    } else {
      alert("No file uploaded yet.");
    }
  };

  return (
    <div>
      {(!userData || !userData?.otp) && <LoginOtp reference={otpReference} />}
      {userData?.otp && (
        <>
          <div
            className="d-flex justify-content-between p-3 align-items-center"
            style={{ backgroundColor: "#274472", color: "white" }}
          >
            <Link to={`/order/${routerParams.id}`}>
              <span>
                <PiArrowLeftBold size={22} className="text-white" />
              </span>
            </Link>
            <span style={{ lineHeight: "0", textAlign: "center" }}>
              <h4>Purchase Order</h4>
              <div>{transactionData[0]?.seller?.name}</div>
            </span>
            <img src={LOGO} alt="" height={60} />
          </div>
          <div className="container-fluid py-5">
            <div style={{ backgroundColor: "white", borderRadius: "3px" }}>
              {transactionData[0]?.order_details?.products?.map(
                (productEl: any,index:any) => (
                  <Card key={index}>
                    <Card.Body>
                      <div className="row align-items-center">
                        <div className="col-md-3">
                          <img
                            src={`${AppConfig.IMAGE_PATH}${productEl.product_image}`}
                            alt=""
                            height={150}
                          />
                        </div>
                        <div className="col-md-6">
                          <p>
                            <b>{productEl.product_name}</b>{" "}
                            {productEl.batch_pricing[0].batch_number}
                          </p>
                          <p>Quantity: {productEl.quantity}</p>
                          <p>
                            Price: {productEl.unit_price}/
                            {productEl.batch_pricing[0]?.quantity_in}
                          </p>
                        </div>
                        <div className="col-md-3">
                          <p>Total: {productEl.total}/-</p>
                          <button
                            className="w-50 rounded"
                            onClick={handleButtonClick}
                          >
                            Upload Invoice
                          </button>
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                          {presignedUrl && <span className="text-center ms-3"><span onClick={()=>handleOpenFile(presignedUrl)} className="primary-color"><ins>Show Invoice</ins></span></span>}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
