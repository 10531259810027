export class S3Helper {
    static async uploadFilesToS3BySigned(
      signedUrl: any,
      file: any,
      fileType: any
    ) {
      try {
        const formData = new FormData();
        formData.append("Content-Type", fileType);
        formData.append("file", file);
        const myHeaders = new Headers({ "Content-Type": fileType });
        const response = await fetch(signedUrl, {
          method: "PUT",
          headers: myHeaders,
          body: file,
        });
        return response;
      } catch (err) {
        return "";
      }
    }
  }
  