import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillHome } from "react-icons/ai";
import { PiArrowLeftBold } from "react-icons/pi";
import { Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { dataDecrypt, dataEncrypt } from '../app/utils/encryption-helper';
import { useSelector } from 'react-redux';
import LoginOtp from './LoginOtp';
import LOGO from './../assets/images/vyapar_logo.svg';
import { ApisService } from '../app/core/apis.service';

export const Packing_Inspections = () => {
  const [packingData, setpackingData] = useState<any>([]);
  const [otpReference, setOtpReference] = useState('');
  const userData = useSelector((state: any) => state.UserDataReducer);
  const [backRouteLink, setBackRouteLink] = useState('');
  const routerParams = useParams();

  const created_date: string = ApisService.formatTimestamp(packingData[0]?.invoice_details?.invoice_date);
  console.log(created_date);

  useEffect(() => {
    loadPacking();
    // setInterval(() => {
    //   console.log('user data ', userData.UserDataReducer);
      
    // }, 1000);
  }, []);

  useEffect(() => {
    console.log('userData ', userData);
    
  }, [userData])
  

  async function loadPacking(): Promise<void> {
    console.log('routerParams ', routerParams);

    try {
      const res = await ApisService.getTransactionData('finInvoiceById', routerParams.id || '');
      const data: any = res.data.data;
      console.log(data)
      const transactionData = JSON.parse(dataDecrypt(data));
      setpackingData([...transactionData]);
      if(transactionData[0]?.transactionId) {
        setBackRouteLink(dataEncrypt(JSON.stringify( {id: transactionData[0].transactionId})));
        const preparedReference = dataEncrypt(JSON.stringify({ invoice: transactionData[0].id, id: transactionData[0].transactionId, type: 'packing' })); 
        setOtpReference(preparedReference);
      }
      console.log('transactionData ', transactionData);
    } catch (error) {
      console.error('Error in transaction:', error);
    }
  }
  return (

    <div>
      {(!userData || !userData?.otp) && <LoginOtp reference={otpReference} />}
      {userData?.otp && <>
        <div className='d-flex justify-content-between p-3 sticky-top' style={{ backgroundColor: "#274472", color: "white", zIndex: "1" }}>
        <Link to={`/order/${backRouteLink}`}><span ><PiArrowLeftBold size={22} className='text-white' /></span></Link>
          <span style={{ lineHeight: "0" }} className='text-center'><h4>Packing & Inspection</h4><div>{packingData[0]?.buyer.name}</div></span>
          <img src={LOGO} alt="" height={60} />

        </div>
        <br />

        <div className='container-fluid'>
          <div style={{ backgroundColor: "white", borderRadius: "3px" }}>
            <Card>
              <Card.Body>
                <div className='row'>
                  <h6 className='col-4'>Name</h6>
                  <span className='col-8'>: {packingData[0]?.seller?.name}(Seller)</span>
                </div>

                <div className='row'>
                  <h6 className='col-4'>Txn Initiated Date</h6>
                  <div className='col-8'>: {created_date}</div>

                </div>

              </Card.Body>
            </Card>
          </div><br />

          <h5 style={{ color: "#274472" }}>Products</h5>
          <div className="card mb-5">
            <div className='card-body'>
              <div className='row'>
                <h6 className='col-4' style={{ color: "#274472" }}>Product Name </h6>
                <div className='col-8'>: {packingData[0]?.invoice_details?.products[0]?.product_name} </div>
              </div>
              <div className='row'>
                <h6 className='col-4' style={{ color: "#274472" }}>Quantity </h6>
                <div className='col-8'>: {packingData[0]?.invoice_details?.products[0]?.quantity} </div>
              </div>
            </div>
          </div>

          <h5 style={{ color: "#274472" }}>Paking Details</h5>
          <div className="card">
            <div className='card-body'>
              <div className='row'>
                <h6 className='col-4'>No.of.Boxes</h6>
                <div className='col-8'>: {packingData[0]?.invoice_details?.package_details?.no_packages}</div>
              </div>
              <div className='row'>
                <h6 className='col-4'>Weight</h6>
                <div className='col-8'>: {packingData[0]?.invoice_details?.package_details?.unit_of_mesurments?.weight}</div>
              </div>
              <div className='row'>
                <h6 className='col-4'>Height</h6>
                <div className='col-8'>: {packingData[0]?.invoice_details?.package_details?.unit_of_mesurments?.height}</div>
              </div>
              <div className='row'>
                <h6 className='col-4'>Width</h6>
                <div className='col-8'>: {packingData[0]?.invoice_details?.package_details?.unit_of_mesurments?.width}</div>
              </div>
              <div className='row'>
                <h6 className='col-4'>Depth</h6>
                <div className='col-8'>: {packingData[0]?.invoice_details?.package_details?.unit_of_mesurments?.depth}</div>
              </div>
              <div className='row'>
                <h6 className='col-4'>Volume</h6>
                <div className='col-8'>: {packingData[0]?.invoice_details?.package_details?.unit_of_mesurments?.volume}</div>
              </div>
            </div>
          </div>
        </div>
      </>}
    </div>
  )
}